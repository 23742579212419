<template>
  <base-layout-two
    :page-title="user.fullname"
    :page-default-back-link="`/users/${$route.params.id}`"
    content-class="background-white"
  >
    <section class="padding-x-16">
      <h1>Team Notes</h1>
      <ion-button class="regular-button" expand="block" :router-link="`/users/${$route.params.id}/activities/notes/create`"
        >New Note</ion-button
      >
    </section>

    <section class="padding-x-16 padding-top-16">
      <team-note-card
        v-for="note in notes"
        :key="note.id"
        :note="note"
      ></team-note-card>
    </section>
  </base-layout-two>
</template>

<script>
import { IonButton } from "@ionic/vue";
import TeamNoteCard from "@/components/users/activities/TeamNoteCard.vue";

export default {
  components: {
    IonButton,
    TeamNoteCard,
  },
  data() {
    return {
      user: {},
      notes: [],
    };
  },
  ionViewWillEnter() {
    this.fetchUser();
    this.fetchNotes();
  },
  methods: {
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchNotes() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/activities?user_id=${this.$route.params.id}&type=note`
        )
        .then((response) => {
          this.notes = response.data.activities.sort((a, b) => {
            return new Date(b.datetime) - new Date(a.datetime);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>